import $ from "jquery";
/* ===============================================================================
=============================================================================== */

let page = 2;

$("#loadMoreBlog").click(function() {
  const button = $(this);

  $.ajax({
    url: "/wp-json/wp/v2/blog?_embed&per_page=6&page=" + page,
    type: "GET",

    beforeSend: function() {
      button.addClass("loadMoreBtn");
    },

    success: function(data) {
      if (data) {
        $("#blogPosts").append(postsHTML(data));
        if (button.data("max-pages") == page) {
          button.remove();
        } else {
          page++;
        }
      } else {
        button.remove();
      }
      button.removeClass("loadMoreBtn");
    }
  });
});

function postsHTML(posts) {
  let postsHTML = "";

  posts.forEach(function(post) {
    // console.log(post.date);
    const title = post.title.rendered,
      link = post.link,
      image = post._embedded["wp:featuredmedia"][0].source_url,
      excerpt = post.excerpt.rendered;
    // content = strip(post.content.rendered).substring(0, 229, "...");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    postsHTML += `<a href="${link}" class="col-md-6 col-lg-4 p-4 blogBox__col">
    <div class="blogBox__colWrap">
        <div class="blogBox__content">
  
            <div class="row align-items-center px-4 pt-4">
                <div class="col-2 date">
                    <div class="date__month">${monthNames[new Date(post.date).getMonth()]}</div>
                    <div class="date__day">${new Date(post.date).getDate()}</div>
                    <div class="date__year">${new Date(post.date).getUTCFullYear()}</div>
                </div>
                <div class="col-10 blogBox__title">${title}</div>
                <div class="col-12 pt-3 blogBox__excerpt">${excerpt}</div>
            </div>
  
        </div>
        <div class="blogBox__img" style="background-image:url('${image}');">
  
        </div>
    </div>
  </a>`;
  });

  return postsHTML;
}
