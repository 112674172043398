import $ from "jquery";

/* ======================== */
/* ======================== */


/* Listen for the input element */


// $('#realSearch').keydown(e => {
//     if ($(e.target).val().trim().length < 2) {
//         $('#searchResult').html('')
//     } else {
//         $.ajax({
//             url: `/wp-json/wp/v2/search?search=${$(e.target).val()}`
//         }).then(resp => {
//             $('#searchResult').html(
//                 resp.map(result => `<a href="${result.url}">${result.title}</a>`)
//             )
//         })
//     };
// })