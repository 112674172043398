import $ from "jquery";
/* ================================================
Bootstrap 4 Hide fixed-top Navbar When Scroll Down
================================================ */
$(function() {
  var lastScrollTop = 0;
  var $navbar = $("header");
  var navbarHeight = $navbar.outerHeight();
  var movement = 0;
  var lastDirection = 0;

  $(window).scroll(function(event) {
    var st = $(this).scrollTop();

    if (st > lastScrollTop && st > 0) {
      // scroll down

      // use this is jQuery full is used
      // $navbar.fadeOut();

      // use this to use CSS3 animation
      $navbar.addClass("header-fade-out");
      $navbar.removeClass("header-fade-in");

      // use this if no effect is required
      // $navbar.hide();
    } else {
      // scroll up

      // use this is jQuery full is used
      // $navbar.fadeIn();

      // use this to use CSS3 animation
      $navbar.addClass("header-fade-in");
      $navbar.removeClass("header-fade-out");

      // Check if its hit top
      if (st <= 0) {
        $navbar.removeClass("header-fade-in");
      }

      // use this if no effect is required
      // $navbar.show();
    }
    lastScrollTop = st;
  });
});

$(".navbar-toggler").on("click", function() {
  $("body").toggleClass("noScroll");
  $("header").toggleClass("navBreak");
});

$("body").on("mouseenter mouseleave", ".nav-item", function(e) {
  if ($(window).width() > 750) {
    var _d = $(e.target).closest(".nav-item");
    _d.addClass("show");
    setTimeout(function() {
      _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
    }, 1);
  }
});

/* Search */
$(".searchBar__button").click(function(e) {
  e.stopPropagation();
  $(this)
    .parent()
    .toggleClass("open");
  $(".searchBar__input").focus();
});

$(document).click(e => {
  if (!$(e.target).is(".searchBar__input")) {
    $(".searchBar").removeClass("open");
  }
});
