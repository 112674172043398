import $ from "jquery";
import "bootstrap";
import "popper.js";

import "./modules/navBar";
import "./modules/loadMore";
import "./custom/jqScript";
import "./custom/scripts";
import "./custom/swiper";
import "./modules/search";
